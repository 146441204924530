.non-touch {
    touch-action: none;
}

.hhXztq {
    top: 400px !important;
}

.cICGgJ {
    top: 150px !important;
}

.no-decoration {
    text-decoration: none;
    /* Remove underline */
    color: inherit;
    /* Use the color inherited from the parent */
}