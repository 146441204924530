.flip-book {
    
    display: none;
    background-size: cover;
}

.page {
    // padding: 20px;

    background-color: hsl(calc(35 / 1%), 55%, 98%);
    color: hsl(calc(35 / 1%), 35%, 35%);
    //border: solid 1px hsl(calc(35 / 1%), 20%, 70%);
    width: 100%;

    overflow: hidden;

    .page-content {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: stretch;
        padding: 0;

        .page-image {
            width: 100%;
            height: 100%;
        }

        .page-header {
            height: 30px;
            font-size: 100%;
            text-transform: uppercase;
            text-align: center;
        }

        .page-image {
            height: 100%;
            background-size: contain;
            background-position: center center;
            background-repeat: no-repeat;
        }

        .page-text {
            height: 100%;
            flex-grow: 1;
            font-size: 80%;
            text-align: justify;
            margin-top: 10px;
            padding-top: 10px;
            box-sizing: border-box;
            //border-top: solid 1px hsl(calc(35 / 1%), 55%, 90%);
        }

        .page-footer {
            height: 30px;
            //border-top: solid 1px hsl(calc(35 / 1%), 55%, 90%);
            font-size: 80%;
            color: hsl(calc(35 / 1%), 20%, 50%);
        }
    }

    &.--left {
        // for left page (property will be added automatically)
        border-right: 0;
        box-shadow: inset -7px 0 30px -7px rgba(0, 0, 0, 0.4);

        .page-content {
            box-shadow: inset -7px 0 30px -7px rgba(0, 0, 0, 0.4);
        }
    }

    &.--right {
        // for right page (property will be added automatically)
        border-left: 0;
        box-shadow: inset 7px 0 30px -7px rgba(0, 0, 0, 0.4);

        .page-content {
            box-shadow: inset 7px 0 30px -7px rgba(0, 0, 0, 0.4);
        }

        .page-footer {
            text-align: right;
        }
    }

    &.hard {
        // for hard page
        background-color: hsl(calc(35 / 1%), 50, 90);
        //border: solid 1px hsl(calc(35 / 1%), 20, 50);
    }

    &.page-cover {
        background-color: hsl(calc(35 / 1%), 45, 80);
        color: hsl(calc(35 / 1%), 35, 35);
        //border: solid 1px hsl(calc(35 / 1%), 20, 50);
        padding: 0;

        .page-image {
            width: 100%;
            height: 100%;
        }

        h2 {
            text-align: center;
            padding-top: 50%;
            font-size: 210%;
        }

        &.page-cover-top {
            box-shadow: inset 0px 0 30px 0px rgba(36, 10, 3, 0.5), -2px 0 5px 2px rgba(0, 0, 0, 0.4);
        }

        &.page-cover-bottom {
            box-shadow: inset 0px 0 30px 0px rgba(36, 10, 3, 0.5), 10px 0 8px 0px rgba(0, 0, 0, 0.4);
        }
    }
}